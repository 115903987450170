.stats-stats {
  width: 100%;
  display: grid;
  padding: var(--dl-space-space-threeunits);
  grid-gap: var(--dl-space-space-twounits);
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  background-color: var(--dl-color-primary-100);
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.stats-stat {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.stats-text {
  color: var(--dl-color-primary-500);
  font-size: 3rem;
}
.stats-text03 {
  color: var(--dl-color-gray-white);
  font-size: 1.15rem;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
}
.stats-stat1 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.stats-text04 {
  color: var(--dl-color-primary-500);
  font-size: 3rem;
}
.stats-text06 {
  color: var(--dl-color-gray-white);
  font-size: 1.15rem;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
}
.stats-stat2 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.stats-text07 {
  color: var(--dl-color-primary-500);
  font-size: 3rem;
}
.stats-text10 {
  color: var(--dl-color-gray-white);
  font-size: 1.15rem;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
}
.stats-stat3 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.stats-text11 {
  color: var(--dl-color-primary-500);
  font-size: 3rem;
}
.stats-text13 {
  color: var(--dl-color-gray-white);
  font-size: 1.15rem;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
}

@media(max-width: 991px) {
  .stats-text {
    text-align: center;
  }
  .stats-text04 {
    text-align: center;
  }
  .stats-text07 {
    text-align: center;
  }
  .stats-text11 {
    text-align: center;
  }
}
@media(max-width: 767px) {
  .stats-stats {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    grid-template-columns: 1fr 1fr;
  }
  .stats-text03 {
    text-align: center;
  }
  .stats-text06 {
    text-align: center;
  }
  .stats-text10 {
    text-align: center;
  }
  .stats-text13 {
    text-align: center;
  }
}
@media(max-width: 479px) {
  .stats-stats {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
    grid-template-columns: 1fr;
  }
}
